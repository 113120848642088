import { WishlistElementHeadless } from 'https://cdn.jsdelivr.net/npm/@appmate/wishlist@4.29.2/wishlist-element-headless.js';

class WishlistLinkHeadless extends WishlistElementHeadless {
  getStateConfig() {
    return {
      wishlist: true,
    };
  }

  getWishlistUrl() {
    const accessMode = this.app.settings.general.wishlistAccessMode;

    if (accessMode === 'REQUIRE_LOGIN_TO_VIEW_LIST' || accessMode === 'REQUIRE_LOGIN_BEFORE_USE') {
      if (this.app.customer) {
        return '/account?view=wishlist';
      }

      return this.app.routes.wishlistUrl;
    }

    return this.app.routes.wishlistUrl;
  }

  updated() {
    const numItems = this.wishlist ? this.wishlist.numItems : 0;

    this.host.href = this.getWishlistUrl();
    this.host.classList.toggle('wk-selected', numItems > 0);
    this.host.querySelector('.wk-counter').innerText = numItems;
  }
}

window.headlessElements.define('wishlist-link-headless', WishlistLinkHeadless);